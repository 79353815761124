import {FormEntry} from "@shared/Form/interface"
import {tick} from "svelte"

export const removeErrorsFromFormEntry = (formEntry: FormEntry): FormEntry => {
    formEntry.errors = []

    if(formEntry.children !== null) {
        if (Array.isArray(formEntry.children)) {
            formEntry.children = formEntry.children.map(child => removeErrorsFromFormEntry(child))
        } else {
            Object.keys(formEntry.children).forEach(key => {
                // @ts-ignore
                formEntry.children[key] = removeErrorsFromFormEntry(formEntry.children[key])
            })
        }
    }

    return formEntry
}

export const scrollToFirstError = (): Promise<void> => tick().then(() => {
    const element = document.querySelector(".form-type-with-errors")
    if(element !== null) {
        element.scrollIntoView()
    }
})